jQuery(document).ready(function () {
  console.log("asdf");
  var _DRAGGGING_STARTED = false;
  var _DIV_OFFSET = jQuery('#image-container').offset();

  jQuery('#image-container').on('mousedown', function (event) {
    console.log("mouse down");
    _DRAGGGING_STARTED = true;
    _LAST_MOUSE_POSITION = {x: event.pageX - _DIV_OFFSET.left, y: event.pageY - _DIV_OFFSET.top};
  });

  jQuery('#image-container').on('mouseup', function () {
    console.log("mouse up");
    _DRAGGGING_STARTED = false;
  });

  jQuery('#image-container').on('mousemove', function (event) {
    if (_DRAGGGING_STARTED === true) {
      console.log("mouse move");
      var current_mouse_position = {x: event.pageX - _DIV_OFFSET.left, y: event.pageY - _DIV_OFFSET.top};
      var change_x = current_mouse_position.x - _LAST_MOUSE_POSITION.x;
      var change_y = current_mouse_position.y - _LAST_MOUSE_POSITION.y;

      /* Save mouse position */
      _LAST_MOUSE_POSITION = current_mouse_position;

      var img_top = parseInt(jQuery("#product-main-image").css('top'), 10);
      var img_left = parseInt(jQuery("#product-main-image").css('left'), 10);

      var img_top_new = img_top + change_y;
      var img_left_new = img_left + change_x;

      jQuery("#product-main-image").css({top: img_top_new + 'px', left: img_left_new + 'px'});
    }
  });
});
