var $body = jQuery('body');
var $window = jQuery(window);
var $homeSlider = jQuery('#home-slider');
var $firstSection = jQuery('section:first');
var SCRIPT = {
    init: function () {
        jQuery.fn.isOnScreen = function () {
            var viewport = {};
            viewport.top = $window.scrollTop();
            viewport.bottom = viewport.top + $window.height();
            var bounds = {};
            bounds.top = this.offset().top;
            bounds.bottom = bounds.top + this.outerHeight();
            return bounds.top <= viewport.bottom && bounds.bottom >= viewport.top;
        };
        SCRIPT.initScroll();
    },

    initParallax: function () {
        jQuery('.parallax-bg img').each(function (index, el) {
            var container = jQuery(this).parent('.parallax-bg');
            var image = jQuery(this).attr('src');

            jQuery(container).css('background-image', 'url(' + image + ')');

            jQuery(this).remove();
        });

        jQuery('.parallax-wrapper').each(function (index, el) {
            var elOffset = jQuery(el).parent().offset().top;
            var winTop = $window.scrollTop();
            var scrll = (winTop - elOffset) * 0.15;

            if (jQuery(el).isOnScreen()) {
                jQuery(el).css('transform', 'translate3d(0, ' + scrll + 'px, 0)');
            }
        });
    },
    initScroll: function () {
        $window
            .on('scroll', function () {
                if (
                    $firstSection.is('.parallax-section, .dark-bg, .home-section, .colored-bg, .gradient-bg') ||
                    $homeSlider.length
                ) {
                    if ($window.width() > 991) {
                        if ($firstSection.hasClass('section-bordered')) {
                            if ($window.scrollTop() <= 20) {
                                $body.addClass('top-spacing');
                            } else {
                                $body.removeClass('top-spacing');
                            }
                        }
                    }
                }

                SCRIPT.initParallax();
            })
            .trigger('scroll');
    },
    fixheight:function () {
        jQuery('.row-mission').each(function () {
            var height = jQuery(this).find('.mission-text').outerHeight(true);
            jQuery(this).find('.mission-image').css('height',height+'px');
        });
        var height = jQuery('.rikki-left').outerHeight(true);
        jQuery('.rikki-right').css('height',height+'px');

        height = jQuery('.rikki-right2').outerHeight(true);
        jQuery('.rikki-left2').css('height',height+'px');

    },
    slidercontent:function () {
        jQuery('.brands').owlCarousel({
            loop:true,
            margin:15,
            stagePadding: 30,
            nav:true,
            dots: false,
            items: 5,
            responsiveClass: true,
            navigation:true,
            lazyLoad:true,
            navText: ["",""],
            responsive:{
                0:{
                    items:1,
                    nav: false
                },
                640:{
                    items:1,
                    nav: false
                },
                768:{
                    items:2,
                    nav: false
                },
                1024:{
                    items:5
                },
                1280:{
                    items:5
                },
                1366:{
                    items:5
                },
                1600:{
                    items:5
                }
            }
        });
        jQuery('.news-press').owlCarousel({
            loop:true,
            margin:10,
            nav:true,
            navigation:true,
            responsiveClass: true,
            dots: false,
            lazyLoad:true,
            autoWidth: false,
            items: 2,
            navText: ["",""],
            responsive:{
                0:{
                    items:1,
                    nav: false
                },
                640:{
                    items:1,
                    nav: false
                },
                768:{
                    items:1,
                    nav: false
                },
                1024:{
                    items:1
                },
                1280:{
                    items:2
                },
                1366:{
                    items:2
                },
                1600:{
                    items:2
                }
            }

        });

        jQuery('.testimonial').owlCarousel({
            loop:true,
            margin:10,
            nav:false,
            dots: true,
            navigation:false,
            lazyLoad:true,
            items: 1,
            responsiveClass: true
        });

    },
    product:function () {
        jQuery('.viewmore').click(function () {
            data= jQuery(this).attr('data');
            jQuery(this).hide();
            jQuery('.viewless'+data).show();
            jQuery('.row_more_product'+data).show();
            jQuery('.itemhidden'+data).show();
        });
        jQuery('.viewless').click(function () {
            data= jQuery(this).attr('data');
            jQuery(this).hide();
            jQuery('.viewmore-product'+data).show();
            jQuery('.row_more_product'+data).hide();
            jQuery('.itemhidden'+data).hide();
        });
    },
    submit:function () {
        jQuery('#form-search .fa-search').click(function () {
            jQuery('#form-search').submit();
        });
    },
    search:function () {
        jQuery('.small-nav i.fa.fa-search, .small-nav a.search.pre-header').click(function () {
            jQuery('.small-nav a.search.pre-header').hide();
            jQuery('.text-search').show();
        });
    }

};
window.onresize = function(event) {
    SCRIPT.fixheight();
};
jQuery(document).ready(function () {
    SCRIPT.submit();
    SCRIPT.init();
    SCRIPT.product();
    SCRIPT.slidercontent();
    setTimeout(function () {
        SCRIPT.fixheight();
    },500);
    SCRIPT.search();
});
