jQuery(window).load(function () {
  var products = jQuery('.product > a > img');
  var greatestImageHeight = 0;

  products.each(function () {
    var height = jQuery(this).height();
    if (height > greatestImageHeight) {
      greatestImageHeight = height;
    }
  });

  if (greatestImageHeight > 150) {
    products.each(function () {
      var height = jQuery(this).height();
      var topBottomPadding = 0;
      jQuery(this).css('margin-bottom', 0);
      if (height !== greatestImageHeight) {
        topBottomPadding = (greatestImageHeight - height) / 2;
        jQuery(this).css('padding-top', topBottomPadding);
        jQuery(this).css('padding-bottom', topBottomPadding);
      }
    });
  }

  if (greatestImageHeight < 150) {
    products.each(function () {
      var height = jQuery(this).height();
      var topBottomPadding = 0;
      if (height !== greatestImageHeight) {
        topBottomPadding = (greatestImageHeight - height) / 2;
        jQuery(this).css('padding-top', topBottomPadding);
        jQuery(this).css('padding-bottom', topBottomPadding);
      }
    });
  }
});
