jQuery(document).ready(function () {
  var scale = 1.0;

  jQuery('.product-image-item').each(function () {
    jQuery(this).on('click', function (i) {
      var selectedImage = jQuery(this).find('img').attr('src');
      var id = (jQuery(this).attr('id'));
      var activeImage = "main-image";
      for (var j=0; j<glasses.length; j++){
        if (id == j){
          activeImage += id;
          jQuery('#'+activeImage).attr('src', selectedImage);
        }
      }
    });
  });

  jQuery('.negative-zoom').on('click', function() {
    if (scale > 0.10000000000000014){
    var activeZoom = "main-image";
    var id = (jQuery(this).attr('id'));
    console.log(id);
    for (var i=0; i<glasses.length; i++){
      if (id == i){
        activeZoom += id;
        scale = (scale - 0.1);
        jQuery('#'+activeZoom).css('transform', 'scale(' + (scale) + ')');
      }
    }
  }
  });

  jQuery('.positive-zoom').on('click', function() {
    if (scale < 2.1){
    var activeZoom = "main-image";
    var id = (jQuery(this).attr('id'));
    console.log(id);
    for (var i=0; i<glasses.length; i++){
      if (id == i){
        activeZoom += id;
        scale = (scale + 0.1);
        jQuery('#'+activeZoom).css('transform', 'scale(' + (scale + 0.1) + ')');
      }
    }
  }
  });

  jQuery('.reset').on('click', function() {
    for (var i=0; i<glasses.length; i++){
        jQuery('#main-image'+i).css('transform', 'scale(' + (1.0) + ')');
        scale = 1.0;
    }
  });
  
});
